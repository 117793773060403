import React, { useEffect } from "react"

import ReactGA from "react-ga4"
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom"

import { GoogleOAuthProvider } from "@react-oauth/google"
import { NotificationProvider, ThemeProvider } from "@synapse-analytics/synapse-ui"

import { Dashboard } from "../components/Dashboard/Dashboard"
import { EntryLayout } from "../features/Auth/EntryLayout"
import { ForgetPassword } from "../features/Auth/ForgetPassword"
import { Login } from "../features/Auth/Login"
import { SignUp } from "../features/Auth/SignUp"
import { UserOrganizationPage } from "../features/Auth/UserOrganizationPage"
import { getTheme } from "../hooks/UseTheme"
import { Auth } from "../utils/auth"
import { getGoogleCreds, getHostnames } from "../utils/genericHelpers"

// Initialize GA if we're hosted in production
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GoogleAnalytics = (): any => {
  const TRACKING_ID = "G-7G2VSRYDX9"
  ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
      // user ip masking
      anonymizeIp: true,
    },
  })

  const location = useLocation()
  // send a pageview event whenever the page changes
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname.split("/").reverse()[0] })
  }, [location.pathname])
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateDashboardRoute(): React.ReactElement {
  const isAuthenticated = Auth.isAuthenticated()
  const hasOrganization = Auth.getOrganizationUID() != null
  const location = useLocation()

  return isAuthenticated && hasOrganization ? (
    /**
     * Outlet component is a wrapper for all child routes under this
     * PrivateDashboardRoute
     */
    <Outlet />
  ) : isAuthenticated && !hasOrganization ? (
    <Navigate
      to={{
        pathname: "/organization",
      }}
      state={{ from: location }}
    />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
      state={{ from: location }}
    />
  )
}

// A wrapper for <Route> that redirects to the new organization
// screen if you're not in one yet.
function PrivateOrganizationRoute(): React.ReactElement {
  const isAuthenticated = Auth.isAuthenticated()
  const hasOrganization = Auth.getOrganizationUID() != null
  const location = useLocation()

  return isAuthenticated && hasOrganization ? (
    <Navigate
      to={{
        pathname: "/",
      }}
      state={{ from: location }}
    />
  ) : isAuthenticated && !hasOrganization ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
      state={{ from: location }}
    />
  )
}

// Reset scroll on location change
function ScrollToTop(): null {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App(): React.ReactElement {
  const theme = getTheme()

  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider maxSnack={3} />
      <Router>
        {/* added google analytics as a tag as useLocation needs to be in a router component to work  */}
        {getHostnames().backend === "api.konan.ai" && <GoogleAnalytics />}
        <ScrollToTop />
        <GoogleOAuthProvider clientId={getGoogleCreds().clientID}>
          <Routes>
            <Route element={<PrivateDashboardRoute />}>
              {/*
               * <Outlet/> component defined earlier is representing the <Dashboard/>
               * component in this example because <Dashboard/> contains all the child routes
               * of the <PrivateDashboardRoute/>
               */}
              <Route path="*" element={<Dashboard />} />
            </Route>
            <Route path="/organization" element={<PrivateOrganizationRoute />}>
              <Route path="" element={<UserOrganizationPage />} />
            </Route>
            <Route element={<EntryLayout />}>
              <Route path="/login" element={<Login redirectPath="/projects" />} />
              <Route path="/reset-password" element={<ForgetPassword />} />
              <Route path="/register" element={<SignUp />} />
            </Route>
          </Routes>
        </GoogleOAuthProvider>
      </Router>
    </ThemeProvider>
  )
}

export { App }
