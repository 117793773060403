import React, { useContext, useState } from "react"

import ReactGA from "react-ga4"
import { useMutation, useQueryClient } from "react-query"
import { useLocation, useNavigate } from "react-router"

import * as Yup from "yup"
import Grid from "@mui/material/Grid"
import { Button, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"

import { KonanAPI } from "../../../services/KonanAPI"
import { CurrentProjectAndModelContext } from "../../../store/CurrentProjectAndModelContext"
import { CreateModelRequest, CreateModelResponse } from "../../../types/custom/projects"
import { ModelCreationForm } from "./ModelCreationForm"
import { PostCreationDialog } from "./PostCreationDialog"

import Styles from "../Projects.module.scss"

// Yup validation object
const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  registry: Yup.string().required("Required"),
  image_url: Yup.string().required("Required"),
  exposed_port: Yup.number().notOneOf([8008, 9090], "Restricted port number").required("Required"),
  docker_username: Yup.string().max(50, "Must be 50 characters or less"),
  docker_password: Yup.string().max(50, "Must be 50 characters or less"),
})

interface Props {
  projectUUID: string
  onClose: () => void
  isFirstModel: boolean
  isProjectCard?: boolean
}

/**
 * Model creation dialog
 * @param  {string} projectUUID
 * @param  {function} onClose
 * @param  {boolean} isFirstModel if first model create a live model else challenger
 * @param  {boolean} isProjectCard project card mode
 * @return  {React.ReactElement}
 */
export function ModelCreationDialog(props: Props): React.ReactElement {
  const { projectUUID, onClose, isFirstModel, isProjectCard } = props

  const queryClient = useQueryClient()

  const { setCurrentModel } = useContext(CurrentProjectAndModelContext)

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [createdModelUUID, setCreatedModelUUID] = useState<string>()

  const [modelLogs, setModelLogs] = useState<{
    errors?: Record<string, string>[]
    logs?: string | null
    loading: boolean
  }>({
    loading: false,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createModelMutation = useMutation<AxiosResponse<CreateModelResponse>, AxiosError<any>, CreateModelRequest>(
    KonanAPI.createModel,
    {
      onMutate: () =>
        setModelLogs({
          loading: true,
        }),
      onSuccess: (response) => {
        setModelLogs({
          errors: response?.data?.errors,
          logs: response?.data?.container_logs,
          loading: false,
        })
      },
      onError: ({ response }) => {
        setModelLogs({
          errors: response?.data?.errors,
          logs: response?.data?.container_logs,
          loading: false,
        })

        NotificationUtils.toast(
          response?.data.name?.[0] ?? response?.data.details ?? "An error occurred, please try again later",
          {
            snackBarVariant: "negative",
          },
        )
      },
    },
  )

  // Model creation form control
  const formik = useFormik({
    initialValues: {
      name: "",
      image_url: "",
      registry: "KCR",
      exposed_port: 8000,
      docker_username: "",
      docker_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Create new Project or Model
      try {
        ReactGA.event({
          category: "Deployments",
          action: "Creating new model",
        })
        const response = await createModelMutation.mutateAsync({
          project_uuid: projectUUID,
          name: values.name,
          image_url: values.image_url,
          exposed_port: values.exposed_port,
          docker_password: values.docker_password || undefined,
          docker_username: values.docker_username || undefined,
          state: isFirstModel ? "live" : "challenger",
        })

        setCreatedModelUUID((response.data as CreateModelResponse).model.uuid)

        queryClient.invalidateQueries(["overviewModels", projectUUID])
        queryClient.invalidateQueries("models")
      } catch (e) {
        ReactGA.event({
          category: "Models",
          action: "model contains errors",
        })
      }
    },
  })

  // handle submitting the model creation form
  // and handle what happens afterwards
  const handleFormSubmit = (): void => {
    if (!createModelMutation.isLoading) {
      formik.submitForm()
    } else {
      onClose()

      if (!createdModelUUID) {
        navigate(`${pathname}/${projectUUID}/overview/`)
      } else {
        setCurrentModel(createdModelUUID)
        if (isProjectCard) {
          navigate(`projects/${projectUUID}/models/requests/`)
        } else {
          navigate(`${pathname.substring(0, pathname.lastIndexOf("/"))}/models/requests/`)
        }
      }
    }
  }

  return (
    <Grid container direction="column" className={Styles.modelCreationDialog}>
      {/* show the post creation dialog if the request doesn't return an error */}
      {modelLogs.logs || modelLogs.loading || modelLogs.errors ? (
        <Grid item className={Styles.imageContainer}>
          <PostCreationDialog
            creationErrors={modelLogs?.errors}
            creationLogs={modelLogs?.logs}
            isLoading={modelLogs?.loading}
          />
        </Grid>
      ) : (
        <ModelCreationForm formik={formik} />
      )}

      {!modelLogs.loading && (
        <Grid container item justifyContent="flex-end" spacing={1} paddingTop={2}>
          <Grid item>
            <Button variant="secondary" onClick={onClose} disabled={formik.isSubmitting} id="cancel">
              {!modelLogs.errors && !modelLogs.logs ? "Cancel" : "Close"}
            </Button>
          </Grid>

          {!modelLogs.errors && !modelLogs.logs && (
            <Grid item>
              <Button
                variant="primary"
                onClick={handleFormSubmit}
                disabled={!(formik.dirty && formik.isValid)}
                id="submit"
              >
                {!modelLogs.logs && !modelLogs.errors
                  ? `Create Model`
                  : `Go to ${!projectUUID ? "deployment" : "model"}`}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}
