import React, { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react"

import { UseMutationResult, useMutation, useQuery, useQueryClient } from "react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { OnEdgesChange, ReactFlowProvider } from "reactflow"

import { CardHeader, CircularProgress, FormControl, Grid, MenuItem, Select, SvgIcon } from "@mui/material"
import { Button, InputText, NotificationUtils, Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { KonanAvatar } from "../../../components/Avatar"
import { InfoContainer } from "../../../components/InfoContainer"
import { VersionTag } from "../../../components/VersionTag"
import { BaseSimpleDialog } from "../../../components/dialogs/BaseSimpleDialog"
import { VersioningDialog } from "../../../components/dialogs/VersioningDialog"
import { WorkflowSchemaDialog } from "../../../components/dialogs/WorkflowSchemaDialog"
import { KonanAPI } from "../../../services/KonanAPI"
import { RootState } from "../../../store/ReduxStore"
import { CreateWorkflowRequest, UpdateWorkflowRequest } from "../../../types/custom/projects"
import { VersionChangeRequest } from "../../../types/custom/rules"
import { PaginatedWorkflowVersionListList } from "../../../types/generated/api/PaginatedWorkflowVersionListList"
import { WorkflowVersionList } from "../../../types/generated/api/WorkflowVersionList"
import { WorkflowVersionRetrieve } from "../../../types/generated/api/WorkflowVersionRetrieve"
import { WorkflowVersionsRetrieve } from "../../../types/generated/api/WorkflowVersionsRetrieve"
import { isPermitted } from "../../../utils/PermissionsHelpers"
import { Auth } from "../../../utils/auth"
import { getValidMaxMinorVersion } from "../../../utils/deploymentDetailsHelpers"
import { convertRetrievedNodesToFlow, getLayoutedElements } from "../../../utils/workflowHelpers"
import { SimulationCreationDialog } from "../../Simulations"
import { OutputFiltersDialog } from "./OutputFiltersDialog"
import { WorkflowCanvas } from "./WorkflowCanvas"

import styles from "../Workflows.module.scss"

type WorkflowSwitch = {
  id: string
  name: string
  newState: string
}

type Props = {
  isCreateMode: boolean
  activeWorkflowName: string
  activeWorkflowId: string
  isWorkflowLoading: boolean
  activeWorkflow: WorkflowVersionRetrieve
  isEditMode: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  workflowName: string | undefined
  workflowState: string | undefined
  isWorkflowsLoading: boolean
  isWorkflowFetching: boolean
  isWorkflowRefetching: boolean
  currentProjectId: string
  activeWorkflowVersion: WorkflowVersionsRetrieve | undefined
  handleEditClicked: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setConfirmCloseDialogOpen: Dispatch<SetStateAction<any>>
  workflowsCount: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createWorkflowMutation: UseMutationResult<AxiosResponse, AxiosError, CreateWorkflowRequest, unknown>
  updateWorkflowMutation: UseMutationResult<AxiosResponse, AxiosError, UpdateWorkflowRequest, unknown>
  versioningContainer: React.ReactElement
  workflowUUID: string
  onEdgesChange: OnEdgesChange
  acceptedFile: File[]
  setAcceptedFile: Dispatch<SetStateAction<Array<File>>>
  handleDuplicateWorkflow: () => void
  setIsDuplicate?: Dispatch<SetStateAction<boolean>>
}

export function WorkflowCanvasHeader(props: Props): React.ReactElement {
  const {
    activeWorkflowId,
    activeWorkflow,
    activeWorkflowName,
    isCreateMode,
    isWorkflowLoading,
    isWorkflowsLoading,
    isWorkflowFetching,
    isWorkflowRefetching,
    isEditMode,
    formik,
    workflowName,
    workflowState,
    currentProjectId,
    activeWorkflowVersion,
    setConfirmCloseDialogOpen,
    handleEditClicked,
    createWorkflowMutation,
    workflowsCount,
    updateWorkflowMutation,
    versioningContainer,
    workflowUUID,
    onEdgesChange,
    acceptedFile,
    setAcceptedFile,
    handleDuplicateWorkflow,
    setIsDuplicate,
  } = props

  const permissions = Auth.getPermissions()

  const { id: projectId } = useParams<{ id: string }>()

  const flattenedKonanPermissions = useSelector((state: RootState) => state.permissions.flattenedKonanPermissions)

  const [workflowToSwitch, setWorkflowToSwitch] = useState<WorkflowSwitch>({ name: "", id: "", newState: "" })

  const [switchWorkflowDialogOpen, setSwitchWorkflowDialogOpen] = useState<boolean>(false)
  const [versioningDialogOpen, setVersioningDialogOpen] = useState<boolean>(false)
  const [isSimulationDialogOpen, setIsSimulationDialogOpen] = useState<boolean>(false)
  const [isOutputFiltersOpen, setIsOutputFiltersOpen] = useState<boolean>(false)
  const [schemaDialogOpen, setSchemaDialogOpen] = useState<boolean>(false)

  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(undefined)
  const [nodes, setNodes] = useState<Node[]>()

  const queryClient = useQueryClient()

  const [cachedResults, setCachedResults] = useState<Record<string, string>>({})

  // switch a workflow
  const switchWorkflowMutation = useMutation<AxiosResponse, AxiosError, UpdateWorkflowRequest>(KonanAPI.updateWorkflow)

  const userEmail = useMemo(() => {
    return Auth.getEmail()
  }, [])

  // TODO: refactor this query to be useInfiniteQuery whith version vialog revamp
  // (SSF and pagination)
  const { data: workflowVersionsData, isLoading: isWorkflowVersionsLoading } = useQuery<
    AxiosResponse<PaginatedWorkflowVersionListList>,
    AxiosError
  >(
    ["workflow-versions", projectId, workflowUUID],
    () =>
      KonanAPI.fetchWorkflowVersions({
        projectUUID: projectId as string,
        workflowUUID,
        pageSize: 200,
      }),
    {
      enabled: !!projectId && !!workflowUUID && versioningDialogOpen,
    },
  )

  //fetch single workflow version
  const { isLoading: isWorkflowVersionLoading, data: workflowVersionData } = useQuery<
    AxiosResponse<WorkflowVersionRetrieve>,
    AxiosError
  >(
    ["workflow-version", projectId, selectedVersion],
    () => KonanAPI.fetchWorkflowVersion(projectId as string, selectedVersion?.toString() as string, workflowUUID),
    {
      enabled: !!projectId && !!selectedVersion && !!workflowUUID,
      onSuccess: () => {
        // only compute the valid max minor version of the fetched version if the major version
        // of the selected version wasn't included in the caching state (cachedResults)
        if (selectedVersion && !cachedResults[selectedVersion?.split(".")?.[0]]) {
          const result = getValidMaxMinorVersion(
            workflowVersionsData?.data?.results as WorkflowVersionList[],
            selectedVersion,
          )

          // add the valid max minor version to our caching state
          setCachedResults((prevResults) => ({
            ...prevResults,
            ...result,
          }))
        }
      },
    },
  )

  const handleSwitchWorkflow = (switchType: string): void => {
    setWorkflowToSwitch({
      id: activeWorkflowId,
      name: activeWorkflowName,
      newState: switchType,
    })
    setSwitchWorkflowDialogOpen(true)
  }

  const handleCancel = (): void => {
    setConfirmCloseDialogOpen({ isOpen: true, action: { name: isCreateMode ? "close" : "edit" } })
    setIsDuplicate?.(false)
  }

  async function switchWorkflow(): Promise<void> {
    try {
      await switchWorkflowMutation.mutateAsync({
        newState: workflowToSwitch?.newState,
        projectUUID: currentProjectId,
        workflowUUID: workflowToSwitch?.id,
      })

      // Invalidate react-query queries
      await queryClient.invalidateQueries("models")
      await queryClient.invalidateQueries("workflow")
      await queryClient.invalidateQueries(["workflows", currentProjectId])

      NotificationUtils.toast(
        "Workflow - " + workflowName + ` - switched to ${workflowToSwitch?.newState?.toLowerCase()} successfully!`,
        {
          snackBarVariant: "positive",
        },
      )
      setSwitchWorkflowDialogOpen(false)
    } catch (e) {
      NotificationUtils.toast("Failed to switch " + workflowName, {
        snackBarVariant: "negative",
      })
    }
  }

  // changing the active version in a workflow group
  const changeWorkflowActiveVersionMutation = useMutation<AxiosResponse, AxiosError, VersionChangeRequest>(
    KonanAPI.changeResourceVersion,
    {
      mutationKey: "changeWorkflowVersion",
    },
  )

  const handleRestoreVersion = async (version: string): Promise<void> => {
    try {
      await changeWorkflowActiveVersionMutation.mutateAsync({
        projectUUID: currentProjectId,
        resourceUUID: workflowUUID ?? "",
        version: version.toString(),
        resource: "workflows",
      })
      await queryClient.invalidateQueries("workflows")
      await queryClient.invalidateQueries(["workflow", currentProjectId, workflowUUID])
      await queryClient.invalidateQueries(["workflow-versions", currentProjectId, workflowUUID])

      NotificationUtils.toast(`Version (${version}) restored.`, {
        snackBarVariant: "positive",
      })
    } catch (error) {
      NotificationUtils.toast(`Couldn't restore version (${version}). Please try again later`, {
        snackBarVariant: "negative",
      })
    }
  }

  const WorkflowDialogContainer = (): React.ReactElement => {
    // extracting the current major version
    const majorSelectedVersion = (selectedVersion ?? activeWorkflow?.version)?.split(".")?.[0]

    // show restore button when the current major version from the selected version/active version existed in our valid versions hashMap
    // and it's not the current active version
    const shouldShowRestoreButton =
      cachedResults[majorSelectedVersion] === (selectedVersion ?? activeWorkflow?.version) &&
      cachedResults[majorSelectedVersion] !== activeWorkflow?.version

    /**
     * whenever the current selected version changes, we want to update the nodes,
     * with the updated version of the newly selected workflow
     * as well as update the layout of the workflow (dagre tree)
     */
    useEffect(() => {
      if (selectedVersion && workflowVersionData?.data) {
        const { nodes, edges } = convertRetrievedNodesToFlow(
          workflowVersionData?.data?.nodes,
          workflowVersionData?.data?.start_node ?? "0",
          "retrieve",
          () => null,
          activeWorkflowVersion?.schema ?? { features: [], is_valid: false },
          formik,
        )
        setNodes([...nodes])
        getLayoutedElements([...nodes], [...edges])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowVersionData, selectedVersion])

    return (
      <Grid item container xs={12} height="100%">
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          className={"versioning-dialog-header"}
        >
          <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
            <Grid item gap={1} xs={9} display="flex">
              <Typography variant="h2-bold" style={{ textTransform: "inherit" }}>
                {workflowName}
              </Typography>

              <VersionTag
                version={selectedVersion ?? activeWorkflowVersion?.version}
                variant={
                  activeWorkflowVersion?.is_deprecated
                    ? "negative"
                    : selectedVersion
                      ? selectedVersion === activeWorkflowVersion?.version
                        ? "positive"
                        : "default"
                      : "positive"
                }
              />
            </Grid>

            {shouldShowRestoreButton && (
              <Button
                variant="secondary"
                size="small"
                onClick={() => handleRestoreVersion(selectedVersion as string)}
                disabled={changeWorkflowActiveVersionMutation.isLoading}
              >
                {changeWorkflowActiveVersionMutation.isLoading ? <CircularProgress size={12} /> : "Restore"}
              </Button>
            )}
          </Grid>
        </Grid>

        <ReactFlowProvider>
          <Grid item container xs={12} className={`versioning-dialog-container layoutflow`}>
            {isWorkflowVersionLoading ? (
              <Grid item xs={12} container minHeight={"100%"} className="dotted-background">
                <InfoContainer
                  icon={<CircularProgress className={styles.circularProgress} />}
                  title="Loading workflow..."
                />
              </Grid>
            ) : selectedVersion && workflowVersionData?.data ? (
              <WorkflowCanvas
                onEdgesChange={onEdgesChange}
                nodes={
                  nodes ??
                  convertRetrievedNodesToFlow(
                    workflowVersionData?.data?.nodes,
                    workflowVersionData?.data?.start_node ?? "0",
                    "retrieve",
                    () => null,
                    activeWorkflowVersion?.schema ?? { features: [], is_valid: false },
                    formik,
                  )?.nodes
                }
                isWorkflowLoading={false}
                edges={
                  convertRetrievedNodesToFlow(
                    workflowVersionData?.data?.nodes,
                    workflowVersionData?.data?.start_node ?? "0",
                    "retrieve",
                    () => null,
                    activeWorkflowVersion?.schema ?? { features: [], is_valid: false },
                    formik,
                  )?.edges
                }
              />
            ) : (
              versioningContainer
            )}
          </Grid>
        </ReactFlowProvider>
      </Grid>
    )
  }

  return (
    <Fragment>
      {switchWorkflowDialogOpen && (
        <BaseSimpleDialog
          open={switchWorkflowDialogOpen}
          onClose={() => setSwitchWorkflowDialogOpen(false)}
          name={workflowToSwitch?.name}
          newState={workflowToSwitch?.newState}
          mode="workflow-switch"
          onAccept={() => switchWorkflow()}
          isLoading={switchWorkflowMutation.isLoading}
        />
      )}

      {schemaDialogOpen && (
        <WorkflowSchemaDialog
          formik={formik}
          open={schemaDialogOpen}
          onClose={() => setSchemaDialogOpen(false)}
          schema={activeWorkflowVersion?.schema}
          acceptedFile={acceptedFile}
          setAcceptedFile={setAcceptedFile}
          activeWorkflowId={activeWorkflowId}
          workflowName={workflowName as string}
          isWorkflowsLoading={isWorkflowsLoading}
          isWorkflowInReadMode={!isCreateMode && !isEditMode}
          isWorkflowDeprecated={activeWorkflowVersion?.is_deprecated as boolean}
        />
      )}

      <VersioningDialog
        activeVersion={activeWorkflow}
        setSelectedVersion={setSelectedVersion}
        selectedVersion={selectedVersion ?? activeWorkflow?.version}
        isOpen={versioningDialogOpen}
        versions={workflowVersionsData?.data?.results}
        isSideBarDataLoading={isWorkflowVersionsLoading}
        children={WorkflowDialogContainer()}
        onClose={() =>
          setTimeout(() => {
            setVersioningDialogOpen(false)
          }, 100)
        }
      />

      {isOutputFiltersOpen && (
        <OutputFiltersDialog
          isOpen={isOutputFiltersOpen}
          onClose={() => setIsOutputFiltersOpen(false)}
          uuid={activeWorkflowId}
          isWorkflowDeprecated={activeWorkflowVersion?.is_deprecated}
          workflow={activeWorkflowVersion as WorkflowVersionsRetrieve}
        />
      )}

      {isSimulationDialogOpen && (
        <SimulationCreationDialog
          open={isSimulationDialogOpen}
          onClose={() => {
            setIsSimulationDialogOpen(false)
          }}
          workflowID={workflowUUID as string}
          workflowName={workflowName as string}
        />
      )}

      <CardHeader
        className={styles.workflowHeader}
        title={
          <Grid container direction={"column"} justifyContent="center" alignItems="flex-start">
            <Grid
              item
              marginBottom={isEditMode || isCreateMode ? 0.9 : 1.5}
              height={isEditMode || isCreateMode ? "35px" : "20px"}
            >
              {(isCreateMode && activeWorkflowId === "new") || isEditMode ? (
                <Grid item className={styles.inputContainer}>
                  <InputText
                    hideDescription
                    id="workflowName"
                    placeholder="Workflow name"
                    value={formik.values.workflowName}
                    key={activeWorkflowId}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.workflowName && Boolean(formik.errors.workflowName) && formik.errors.workflowName
                    }
                    handleBlur={formik.handleBlur}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              ) : !isWorkflowLoading && !isCreateMode && formik.values.workflowName && !isEditMode ? (
                <Grid item xs={12} container>
                  <Typography variant="h3-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
                    {formik.values.workflowName}
                  </Typography>
                  {activeWorkflowVersion?.version && (
                    <VersionTag
                      version={activeWorkflowVersion.version}
                      variant={
                        activeWorkflowVersion?.is_deprecated
                          ? "negative"
                          : workflowState === "DISABLED"
                            ? "default"
                            : "positive"
                      }
                    />
                  )}
                </Grid>
              ) : !isWorkflowLoading && !isCreateMode && workflowName && !isEditMode ? (
                <Grid item xs={12} container>
                  <Typography variant="h3-bold" style={{ textTransform: "inherit", marginRight: "5px" }}>
                    {workflowName ?? <Skeleton width={200} height={15} />}
                  </Typography>
                  {activeWorkflowVersion?.version && (
                    <VersionTag
                      version={activeWorkflowVersion.version}
                      variant={
                        activeWorkflowVersion?.is_deprecated
                          ? "negative"
                          : workflowState === "DISABLED"
                            ? "default"
                            : "positive"
                      }
                    />
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} marginTop={0.5}>
                  <Skeleton width={200} height={15} />
                </Grid>
              )}
            </Grid>
            <Grid container item justifyContent="space-between" alignItems={"start"}>
              <Grid flexWrap={"nowrap"} container alignItems="flex-start" item width={"fit-content"}>
                <Grid item marginTop={"5px"}>
                  {(!isWorkflowLoading && workflowName && currentProjectId) || (isCreateMode && !isWorkflowLoading) ? (
                    <Grid item sx={{ marginTop: "2px" }}>
                      {/* SvgIcon is used to fix square avatars on safari */}
                      <SvgIcon className={styles.avatar}>
                        <KonanAvatar
                          size={24}
                          name={!isCreateMode ? activeWorkflowVersion?.created_by : userEmail ? userEmail : "User"}
                        />
                      </SvgIcon>
                    </Grid>
                  ) : (
                    <Skeleton variant="circular" width={30} height={30} />
                  )}
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <Typography
                    variant="a"
                    style={{ marginLeft: "5px", marginTop: isCreateMode || isEditMode ? "10px" : "0px" }}
                  >
                    {/* show created by only when workflow retrieved and not edit or create mode */}
                    {!isCreateMode && !isEditMode && activeWorkflowVersion?.created_by ? (
                      `${activeWorkflowVersion?.created_by}`
                    ) : // show userEmail when it's create or edit mode and workflow query isn't loading
                    (isCreateMode || isEditMode) && userEmail && !isWorkflowLoading ? (
                      `${userEmail}`
                    ) : (
                      // else, show skeleton loader
                      <Skeleton width={"120px"} height={15} variant="rectangular" />
                    )}
                  </Typography>
                  {!isCreateMode && !isEditMode && (
                    <Typography variant="label" noWrap style={{ marginLeft: "5px" }}>
                      {!isCreateMode && workflowName && activeWorkflowVersion && activeWorkflowVersion?.created_at ? (
                        <Tooltip
                          title={
                            activeWorkflowVersion &&
                            activeWorkflowVersion?.created_at &&
                            format(new Date(activeWorkflowVersion?.created_at), "dd/MM/yyyy, p")
                          }
                          placement="right"
                        >
                          <Typography variant="span" noWrap className={styles.projectDate}>
                            {activeWorkflowVersion &&
                              activeWorkflowVersion?.created_at &&
                              moment(new Date(activeWorkflowVersion?.created_at))?.fromNow()}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Grid item mt={1}>
                          <Skeleton width={"80px"} height={12} variant="rectangular" />
                        </Grid>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid item justifyContent="end">
                {!isCreateMode && workflowsCount > 0 && !isEditMode && (
                  <div className={styles.buttonsContainer}>
                    <Button
                      onClick={handleDuplicateWorkflow}
                      variant="secondary"
                      className="workflow-edit-button"
                      style={{ marginTop: "5px", marginRight: "8px" }}
                      disabled={isWorkflowLoading || isWorkflowsLoading}
                    >
                      Duplicate
                    </Button>
                    <Button
                      onClick={() => setVersioningDialogOpen(true)}
                      variant="secondary"
                      // size="regular"
                      className="workflow-edit-button"
                      style={{ marginTop: "5px", marginRight: "8px" }}
                      disabled={isWorkflowLoading || isWorkflowsLoading}
                    >
                      History
                    </Button>
                    <Button
                      variant="secondary"
                      // size="regular"
                      style={{ marginRight: "8px", marginTop: "5px", display: "flex", alignItems: "center" }}
                      onClick={() => setSchemaDialogOpen(true)}
                      disabled={isWorkflowLoading || isWorkflowsLoading}
                    >
                      Schema
                    </Button>

                    {isPermitted("Add workflow response filter", permissions.konan, flattenedKonanPermissions) && (
                      <Button
                        onClick={() => setIsOutputFiltersOpen(true)}
                        variant="secondary"
                        // size="regular"
                        className="workflow-edit-button"
                        style={{ marginTop: "5px", marginRight: "4px" }}
                        disabled={isWorkflowLoading || isWorkflowsLoading}
                      >
                        Output filters
                      </Button>
                    )}

                    {/* Enable switch workflow state, if it's not depreacted */}
                    {!activeWorkflowVersion?.is_deprecated && (
                      <FormControl variant="standard" size="small">
                        <Select
                          value={"default"}
                          style={{ marginTop: "6px", marginLeft: "4px" }}
                          disabled={isWorkflowLoading || isWorkflowsLoading}
                          disableUnderline
                          className={"switch-state-card"}
                          SelectDisplayProps={{ style: { paddingTop: 1, paddingBottom: 1 } }}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(e: any) => handleSwitchWorkflow(e.target.value as string)}
                        >
                          <MenuItem value={"default"} disabled style={{ display: "none" }}>
                            <Typography variant="label" style={{ marginTop: "4px" }}>
                              Switch
                            </Typography>
                          </MenuItem>

                          <MenuItem value={"ACTIVE"} disabled={workflowState === "ACTIVE"}>
                            <Typography variant="label" style={{ marginTop: "4px" }}>
                              Activate
                            </Typography>
                          </MenuItem>
                          <MenuItem value={"DISABLED"} disabled={workflowState === "DISABLED"}>
                            <Typography variant="label" style={{ marginTop: "4px" }}>
                              Disable
                            </Typography>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    {/* Deprecated workflows are read-mode only. cannot be simulated nor edited */}
                    {!activeWorkflowVersion?.is_deprecated && (
                      <Fragment>
                        <Button
                          onClick={() => setIsSimulationDialogOpen(true)}
                          variant="secondary"
                          // size="regular"
                          style={{ marginTop: "5px", marginLeft: activeWorkflowVersion?.is_deprecated ? "4px" : "8px" }}
                          disabled={isWorkflowLoading || isWorkflowsLoading}
                        >
                          Simulate
                        </Button>
                        <Button
                          onClick={() => handleEditClicked()}
                          style={{ marginTop: "5px" }}
                          variant="secondary"
                          // size="regular"
                          className={styles.workflowEditButton}
                          disabled={
                            isWorkflowLoading ||
                            isWorkflowsLoading ||
                            isWorkflowFetching ||
                            isWorkflowRefetching ||
                            updateWorkflowMutation.isLoading
                          }
                        >
                          Edit
                        </Button>
                      </Fragment>
                    )}
                  </div>
                )}
                {(isCreateMode || isEditMode) && (
                  <div className={styles.buttonsContainer}>
                    <Button
                      variant="secondary"
                      // size="regular"
                      style={{ marginRight: "8px", marginTop: "5px", display: "flex", alignItems: "center" }}
                      onClick={() => setSchemaDialogOpen(true)}
                    >
                      Schema
                    </Button>
                    <Button
                      variant="secondary"
                      // size="regular"
                      style={{ marginRight: "8px" }}
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      // size="regular"
                      disabled={
                        createWorkflowMutation.isLoading ||
                        updateWorkflowMutation.isLoading ||
                        !formik.values.workflowName
                      }
                      onClick={async () => {
                        await formik.submitForm()
                      }}
                    >
                      {createWorkflowMutation.isLoading || updateWorkflowMutation.isLoading ? (
                        <CircularProgress color="inherit" size={16} />
                      ) : isCreateMode ? (
                        "Create"
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Fragment>
  )
}
