import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"

import {
  Edge,
  Handle,
  Node,
  Position,
  getConnectedEdges,
  getOutgoers,
  useReactFlow,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow"

import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import FilterListIcon from "@mui/icons-material/FilterList"
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined"
import { Card, Grid, IconButton } from "@mui/material"
import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { FieldArray, FormikProps, FormikProvider, useFormik } from "formik"
import { v4 as uuidv4 } from "uuid"

import { CustomTooltip } from "../../../components/UI/CustomTooltip"
import { getTheme } from "../../../hooks/UseTheme"
import { FilterCondition } from "../../../types/custom/rules"
import { SchemaFeature, UpdateGraphParams } from "../../../types/custom/workflows"
import { ConditionListFile } from "../../../types/generated/api/ConditionListFile"
import { levelSchema } from "../../../utils/conditionHelpers"
import { getDescendantNodes, getLayoutedElements, getNodeHandleXPosition } from "../../../utils/workflowHelpers"
import { EMPTY_FILTER_CONDITION } from "../workflow-fixtures"
import { Condition } from "./Condition"
import { FilterDeletionDialog } from "./FilterNodeDeletionDialog"

import styles from "./CustomCards.module.scss"

// Yup validation schema
const validationSchema = Yup.object({
  rules: Yup.array().of(levelSchema),
})

// props for filter node
type FilterNodeType = {
  id?: string
  menuView?: boolean
  data?: {
    condition: string
    condition_list_files: ConditionListFile[] | null
    fileData: ConditionListFile
    readMode: boolean
    editMode: boolean
    isNodeInLoop: boolean
    feature: string
    operator: string
    type: string
    value: string
    filesCount: number
    secondValue: string
    secondFeature: string
    valueOrFeature: "Value" | "Feature"
    updateGraph: (data: UpdateGraphParams) => void
    rules: Array<FilterCondition>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
  }
}

type SelectedBranchToDelete = "True branch" | "False branch"

type DeletionCase =
  | "DeleteNodeAndAllChildrenNodes"
  | "DeleteEmptyNodeInsideLoop"
  | "DeleteSelectedBranch"
  | "DeleteNodeAndKeepConnectedBranch"

export type FilterBranchDeletionOptions = "Filter only" | "True branch" | "False branch" | "All nodes"

export interface FilterFormikValues {
  rules: Array<FilterCondition>
  filesCount: number
  fileData: ConditionListFile
  condition_list_files: ConditionListFile[]
}

/**
 * Filter node component
 * @param {string} id filter node id
 * @param {boolean} menuView indicator prop wether the view is menu or full canvas
 * @param data all important data for filter nodes (condition and form values)
 * @return {React.ReactElement}
 */
export function FilterNode(props: Readonly<FilterNodeType>): React.ReactElement {
  const { menuView } = props

  const readMode = props?.data?.readMode
  const editMode = props?.data?.editMode
  const isNodeInLoop = props?.data?.isNodeInLoop

  const theme = getTheme()

  // this is react-flow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { getEdges, getNode } = useReactFlow()

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()

  const updateNodeInternals = useUpdateNodeInternals()

  const { setNodes, getNodes, edges } = store.getState()

  /**
   * in the next few lines, we memoizing re-usable values across the components that we use in many functions
   */
  const nodes = getNodes()

  const sourceEdges = getEdges()?.filter((edge) => edge?.source === props?.id)
  const currentNode = nodes?.find((node) => node?.id === props?.id)

  const originalEdge = getEdges()?.find((edge) => edge.target === props?.id)

  const outgoers = currentNode && getOutgoers(currentNode, nodes, getEdges())

  const targetEdges = getEdges()?.filter((edge) => edge?.source === props?.id)

  const targetNodes = targetEdges.map((edge) => getNode(edge.target)).filter((node) => node)

  const nodeWidth = nodes?.find((node) => node.id === props?.id)?.width

  // states
  const [confirmRemoveNodeDialogOpen, setConfirmRemoveNodeDialogOpen] = useState<{
    isOpen: boolean
    options: Array<FilterBranchDeletionOptions>
  }>({ isOpen: false, options: ["Filter only"] })

  const [shouldReCalculateLayout, setShouldReCalculateLayout] = useState<boolean>(false)

  // formik initial values
  const formik = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: {
      rules: props?.data?.rules || [EMPTY_FILTER_CONDITION],
      filesCount: props?.data?.filesCount ?? 0,
      fileData: props?.data?.fileData || { name: "", uuid: "" },
      condition_list_files: props?.data?.condition_list_files || null,
    },

    onSubmit: () => {
      formik?.resetForm()
    },
  })

  // memoizing schema and CFNs
  const currentSchema = useMemo(() => {
    return !readMode || editMode ? props?.data?.formik?.values?.features : props?.data?.schema?.features
  }, [editMode, props?.data?.formik?.values?.features, props?.data?.schema?.features, readMode])

  const computedFeatureNames = useMemo(() => {
    return props?.data?.formik?.values?.computedFeatures
      ?.filter((item: { uuid: string; value: string }) => item?.value?.length > 0)
      ?.map((feat: { uuid: string; value: string }) => feat?.value)
  }, [props?.data?.formik?.values?.computedFeatures])

  // state contains all features (computed, schema)
  // this order is a must because it's derived state based on the current schema and computedFeatureNames
  const [allFeatures, setAllFeatures] = useState([
    ...(currentSchema ?? []),
    ...(computedFeatureNames?.length > 0
      ? computedFeatureNames?.map((name: string) => {
          return {
            name,
            type: "NUMBER",
            is_required: false,
            new: false,
            id: uuidv4(),
            source: "workflow",
          }
        })
      : []),
  ])

  /**
   * This useCallback groups common patterns for multiple flows when deleting filter nodes
   * either DeleteNodeAndAllChildrenNodes  or DeleteSelectedBranch have very similar flow so we group them in this useCallback
   */
  const getValidNodesAndEdgesAfterDeletion = useCallback(
    (
      deletionType: "DeleteNodeAndAllChildrenNodes" | "DeleteSelectedBranch",
      branchToDelete?: SelectedBranchToDelete,
    ) => {
      // map the deleted branch to the name of the filter branch saved in node.data ("true"/"false")
      const deletedBranch = branchToDelete === "True branch" ? "true" : "false"

      // the first node in the branch we wanna delete (this in case the deletion type was equal to DeleteSelectedBranch)
      const startNodeOfDeletedBranch = outgoers?.find((node) => node?.data?.filterBranch === deletedBranch)

      // the first node in the branch we wanna keep (this in case the deletion type was equal to DeleteSelectedBranch)
      const startNodeOfBranchToKeep = outgoers?.find(
        (node) => node?.data?.filterBranch === (deletedBranch === "false" ? "true" : "false"),
      )

      // get all Descendant nodes starting from the node we wanna delete (filter node)
      const deletedNodes = getDescendantNodes(
        getNodes(),
        deletionType === "DeleteSelectedBranch" ? (startNodeOfDeletedBranch?.id as string) : (props?.id as string),
        isNodeInLoop ? "filter-inside-loop" : "filter-outside-loop",
      )

      if (deletionType === "DeleteSelectedBranch") {
        deletedNodes.push(currentNode as Node, startNodeOfDeletedBranch as Node)
      } else if (deletionType === "DeleteNodeAndAllChildrenNodes" && isNodeInLoop) {
        // Add the current node to the list of nodes to delete
        deletedNodes.push(currentNode as Node)
      }

      // find the loop end if existed
      const targetLoopEndNode = getNodes().find(
        (node) => node?.data?.nodeType === "LoopEndNode" && node?.data?.parentLoopId === currentNode?.data?.loopId,
      )

      // Create a Set of descendant node IDs for faster lookup
      const deletedNodeIds = new Set(deletedNodes.map((node) => node?.id))

      // Find all edges connected to the nodes to be deleted
      const edgesToRemove = edges.filter((edge) => deletedNodeIds.has(edge.source) || deletedNodeIds.has(edge.target))

      // Find the remaining edges
      const edgesToRemoveIds = new Set(edgesToRemove.map((edge) => edge?.id))

      // filter the valid edges
      const validEdges = edges.filter((edge) => !edgesToRemoveIds.has(edge.id) && edge.target !== props?.id)

      // filter the valid nodes
      const validNodes = getNodes()
        .filter((node) => !deletedNodes.some((deletedNode) => deletedNode?.id === node?.id))
        .map((node) => {
          const newNode = { ...node }

          if (deletionType === "DeleteSelectedBranch") {
            /**
             * if we reached the targeted loop end node, find a source of a valid edge as a parentId for this node
             * this is because the loop end can have multiple nodes pointing to it (parents). so we wanna update it after deletion
             * to an existing parent
             * if not, we keep the original parentId
             */
            if (newNode.id === targetLoopEndNode?.id) {
              newNode.data = {
                ...newNode?.data,
                parentId:
                  validEdges?.find((edge) => edge?.target === targetLoopEndNode?.id)?.source ?? newNode?.data?.parentId,
              }
            }

            // update the parentId of the first valid node in the branch we keeping
            if (newNode.id === startNodeOfBranchToKeep?.id) {
              newNode.data = {
                ...newNode?.data,
                parentId: originalEdge?.source,
              }
            }
          } else if (deletionType === "DeleteNodeAndAllChildrenNodes") {
            // if we are deleting all nodes, and we in a loop, then update the parentId of the loop end node
            // to the parent of the deleted filter
            if (isNodeInLoop && newNode.id === targetLoopEndNode?.id) {
              newNode.data = {
                ...node?.data,
                parentId: currentNode?.data?.parentId,
              }
            }

            // if we are not in a loop, just simply replace the type of this filter with addBlockNode
            else if (node.id === props?.id && !isNodeInLoop) {
              newNode.type = "AddBlockNode"

              // Destructure the data object from newNode
              const { data } = newNode

              // Extract the desired properties from data
              const { updateGraph, parentId, formik, schema } = data

              // Assign the filtered data back to newNode
              newNode.data = { updateGraph, parentId, formik, schema }
            }
          }

          return newNode
        })

      // modify the target node of the edge that was pointing to this filter
      // in case we selected a branch to delete, then our target is the first valid node in the branch we keeping
      // if we deleting all nodes and in a loop, then point to loop end
      // else, then we outside a loop just keep the target as it's
      const modifiedOGEdge: Edge = {
        ...originalEdge,
        id: originalEdge?.id as string,
        source: originalEdge?.source as string,
        target:
          deletionType === "DeleteSelectedBranch"
            ? (startNodeOfBranchToKeep?.id as string)
            : isNodeInLoop
              ? (targetLoopEndNode?.id as string)
              : (originalEdge?.target as string),
        targetHandle: `${uuidv4()}`,
      }

      return {
        nodes: validNodes,
        edges:
          modifiedOGEdge?.data?.text === "true" ? [modifiedOGEdge, ...validEdges] : [...validEdges, modifiedOGEdge],
      }
    },
    [currentNode, edges, getNodes, isNodeInLoop, props?.id, originalEdge, outgoers],
  )

  // handler for ensuring layout updates after adding/removing rules from filter
  const handleRulesUpdate = (newRules: Array<FilterCondition>): void => {
    // triggering formik update to ensure re-rendering
    props?.data?.formik?.setFieldValue("renderControl", props?.data?.formik?.values?.renderControl + 1)

    setNodes(
      getNodes().map((node) => {
        const newNode = { ...node }
        if (node.id === props?.id) {
          newNode.data = {
            ...newNode.data,
            rules: newRules,
            // rulesValidations: formik?.errors,
            formik: {
              ...props?.data?.formik,
              values: {
                ...props?.data?.formik?.values,
                renderControl: props?.data?.formik?.values?.renderControl + 1,
              },
            },
          }
        }
        return newNode
      }),
    )

    setShouldReCalculateLayout(true)
  }

  const handleCloseFilterClick = (): void => {
    // pre-step: making sure filter has 2 edges connected
    if (targetNodes?.length === 2) {
      // first case, if both routes are empty -> 2 filter edges pointing to add block node
      if (targetNodes?.every((node) => node?.type === "AddBlockNode")) {
        handleFilterRemove({ deletionCase: "DeleteNodeAndAllChildrenNodes" })
      }

      // similar to the first case but both filter routes pointing to end loop (also empty but needs different action)
      else if (targetNodes?.every((node) => node?.type === "LoopEndNode")) {
        handleFilterRemove({ deletionCase: "DeleteEmptyNodeInsideLoop" })
      }

      // second case, if one route is connected and the other is empty
      else if (
        isNodeInLoop
          ? targetNodes?.some((node) => node?.type === "LoopEndNode")
          : targetNodes?.some((node) => node?.type === "AddBlockNode")
      ) {
        setConfirmRemoveNodeDialogOpen({
          isOpen: true,
          options: ["Filter only", "All nodes"],
        })
      }

      // third case if both target nodes not empty
      else if (
        isNodeInLoop
          ? targetNodes?.every((node) => node?.type !== "LoopEndNode")
          : targetNodes?.every((node) => node?.type !== "AddBlockNode")
      ) {
        setConfirmRemoveNodeDialogOpen({
          isOpen: true,
          options: ["All nodes", "True branch", "False branch"],
        })
      }
    }
  }

  const handleFilterRemove = (deletionInfo: {
    deletionCase: DeletionCase
    branchToDelete?: SelectedBranchToDelete
  }): void => {
    const { deletionCase: filterDeletionCase, branchToDelete } = deletionInfo

    switch (filterDeletionCase) {
      case "DeleteNodeAndAllChildrenNodes":
        const validNodesAndEdges = getValidNodesAndEdgesAfterDeletion("DeleteNodeAndAllChildrenNodes")

        if (props?.data?.updateGraph) {
          props?.data?.updateGraph({
            dataAfterDeletion: { nodes: validNodesAndEdges.nodes, edges: validNodesAndEdges.edges },
          })
        }
        break

      case "DeleteEmptyNodeInsideLoop":
        // getting all the updated nodes that we want to keep in the next state update
        const newNodes = nodes?.filter((node) => node.id !== props?.id)

        if (outgoers) {
          const loopEndNodeIndex = newNodes.findIndex((node) => node.id === outgoers[0].id)
          if (loopEndNodeIndex !== -1) {
            newNodes[loopEndNodeIndex].data = {
              ...newNodes[loopEndNodeIndex]?.data,
              parentId: currentNode?.data?.parentId,
            }
          }
        }

        // modify the source edge that points to the deleted filter to point the node after the filter, loop end in this case
        const modifiedEdge: Edge = {
          ...originalEdge,
          id: originalEdge?.id as string,
          source: originalEdge?.source as string,
          target: targetNodes[0]?.id as string,
          targetHandle: `${uuidv4()}`,
        }

        // preparing the new edges
        const newEdges = getEdges()?.filter((edge) => edge.source !== props?.id && edge.id !== originalEdge?.id)

        // setting the nodes and edges after deletion by calling the updateGraph function
        if (props?.data?.updateGraph) {
          props?.data?.updateGraph({
            dataAfterDeletion: {
              nodes: newNodes,
              edges: modifiedEdge?.data?.text === "true" ? [modifiedEdge, ...newEdges] : [...newEdges, modifiedEdge],
            },
          })
        }

        break

      case "DeleteNodeAndKeepConnectedBranch":
        const startNodeOfWrongBranch = isNodeInLoop
          ? outgoers?.find((node) => node?.data?.nodeType === "LoopEndNode")
          : outgoers?.find((node) => node?.data?.nodeType === "AddBlockNode")

        const newValidNodes = isNodeInLoop
          ? getNodes().filter((node) => node.id !== props?.id)
          : getNodes().filter((node) => node.id !== startNodeOfWrongBranch?.id && node.id !== props?.id)

        const linkedNode = outgoers?.find((node) =>
          isNodeInLoop ? node?.data?.nodeType !== "LoopEndNode" : node?.data?.nodeType !== "AddBlockNode",
        )

        const connectedEdges = getConnectedEdges([currentNode as Node], edges)

        const targetNodeIndex = newValidNodes.findIndex((node) => node.id === linkedNode?.id)

        newValidNodes[targetNodeIndex].data = {
          ...newValidNodes[targetNodeIndex]?.data,
          parentId: currentNode?.data?.parentId,
        }

        const modifiedEdge2: Edge = {
          ...originalEdge,
          id: originalEdge?.id as string,
          source: originalEdge?.source as string,
          target: linkedNode?.id as string,
        }

        // We create a new array of edges - remainingEdges -
        // that contains all the edges in the flow that have nothing to do with the node(s) we just deleted.
        const remainingValidEdges = edges.filter((edge) => !connectedEdges.includes(edge))

        if (isNodeInLoop) {
          const validParentForLoopEndNode = remainingValidEdges?.find(
            (edge) => edge?.target === startNodeOfWrongBranch?.id,
          )?.source

          const loopEndIndex = newValidNodes?.findIndex((node) => node.id === startNodeOfWrongBranch?.id)

          newValidNodes[loopEndIndex].data = {
            ...newValidNodes[loopEndIndex]?.data,
            parentId: validParentForLoopEndNode,
          }
        }

        if (props?.data?.updateGraph) {
          props?.data?.updateGraph({
            dataAfterDeletion: { nodes: newValidNodes, edges: [...remainingValidEdges, modifiedEdge2] },
          })
        }

        break

      case "DeleteSelectedBranch":
        const newNodesAndEdges = getValidNodesAndEdgesAfterDeletion("DeleteSelectedBranch", branchToDelete)

        if (props?.data?.updateGraph) {
          props?.data?.updateGraph({
            dataAfterDeletion: { nodes: newNodesAndEdges.nodes, edges: newNodesAndEdges.edges },
          })
        }
        break

      default:
        break
    }

    NotificationUtils.toast(`Filter node removed!`, {
      snackBarVariant: "positive",
    })
  }

  const handleSelectedOption = (selectedOption: FilterBranchDeletionOptions): void => {
    switch (selectedOption) {
      case "Filter only":
        handleFilterRemove({ deletionCase: "DeleteNodeAndKeepConnectedBranch" })
        break

      case "All nodes":
        handleFilterRemove({ deletionCase: "DeleteNodeAndAllChildrenNodes" })
        break

      case "False branch":
        handleFilterRemove({ deletionCase: "DeleteSelectedBranch", branchToDelete: "False branch" })
        break

      case "True branch":
        handleFilterRemove({ deletionCase: "DeleteSelectedBranch", branchToDelete: "True branch" })
        break

      default:
        break
    }
  }

  // Trigger layout update only when shouldReCalculateLayout is true
  useEffect(() => {
    if (shouldReCalculateLayout) {
      const { condition_list_files, fileData, filesCount, rules } = formik?.values
      setTimeout(() => {
        getLayoutedElements(
          getNodes().map((node) =>
            node.id === props?.id
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    rules,
                    fileData,
                    filesCount,
                    condition_list_files,
                    formik: props?.data?.formik,
                    rulesValidations: formik?.errors,
                  },
                }
              : node,
          ),
          edges,
        )
      }, 100)
    }
  }, [edges, getNodes, props?.id, props?.data?.formik, shouldReCalculateLayout, formik?.values, formik?.errors])

  // Update allFeatures whenever currentSchema or computedFeatureNames change
  useEffect(() => {
    const computedFeatures =
      computedFeatureNames?.map((name: string) => ({
        name,
        type: "NUMBER",
        is_required: false,
        new: false,
        id: uuidv4(),
        source: "workflow",
      })) || []

    const updatedAllFeatures = [
      ...(currentSchema?.filter((feat: SchemaFeature) => !!feat?.name) ?? []),
      ...(computedFeatures ?? []),
    ]
    setAllFeatures(updatedAllFeatures)
  }, [currentSchema, computedFeatureNames])

  useEffect(() => {
    updateNodeInternals(props?.id as string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id, sourceEdges, nodeWidth])

  return (
    <div>
      {confirmRemoveNodeDialogOpen.isOpen && (
        <FilterDeletionDialog
          isLoading={false}
          options={[...confirmRemoveNodeDialogOpen.options]}
          onAccept={() =>
            setConfirmRemoveNodeDialogOpen({
              isOpen: false,
              options: [],
            })
          }
          handleAccept={(selectedOption) => {
            setConfirmRemoveNodeDialogOpen({
              isOpen: false,
              options: [],
            })

            handleSelectedOption(selectedOption)
            return selectedOption
          }}
          open
          onClose={() => {
            setConfirmRemoveNodeDialogOpen({
              isOpen: false,
              options: [],
            })
          }}
          mode="remove-filter-node"
          name=""
        />
      )}

      <Card className={menuView ? styles.card : `${styles.filterCard} disableKeyboardA11y nodrag`}>
        {!menuView && (
          <Handle style={{ opacity: 0 }} type="target" position={Position.Top} id="b" isConnectable={true} />
        )}
        <Grid
          display="flex"
          container
          flexWrap="nowrap"
          justifyContent={menuView ? "space-between" : "flex-start"}
          gap={menuView ? 0.5 : 0}
        >
          <Grid item xs={menuView ? 1.7 : 0.8}>
            <Grid item className={styles.filterCardType}>
              <FilterListIcon sx={{ color: theme.palette.grayscale.text[1] }} />
            </Grid>
          </Grid>
          <Grid
            xs={menuView ? true : 11.2}
            item
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={0.5}
          >
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={11}>
                <Typography variant="label" variantColor={2}>
                  FILTER
                </Typography>
              </Grid>

              {/* Close icon */}
              {((!menuView && editMode) || (!readMode && !editMode && !menuView)) && (
                <Grid item marginRight={"-5px"} justifySelf={"flex-end"}>
                  <IconButton style={{ marginTop: "-15px" }} size="small" onClick={handleCloseFilterClick}>
                    <CloseIcon fontSize="small" className={styles.remove} />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <FormikProvider value={formik}>
              <FieldArray name="rules">
                {({ remove: removeRule, push: addRule }) => (
                  <Fragment>
                    {formik?.values?.rules?.map((item, index) => (
                      <Condition
                        key={`${item?.id}-${index}`}
                        isReadMode={Boolean(props?.data?.readMode)}
                        isMenuView={Boolean(menuView)}
                        id={`rules[${index}]`}
                        nodeId={props?.id as string}
                        schema={currentSchema}
                        allFeatures={allFeatures}
                        isEditMode={Boolean(props?.data?.editMode)}
                        formik={formik}
                        index={index}
                        handleRemoveRule={() => {
                          removeRule(index)

                          setTimeout(() => {
                            handleRulesUpdate(formik?.values?.rules?.filter((_, idx) => idx !== index))
                          }, 100)
                        }}
                        workflowFormik={props?.data?.formik}
                      />
                    ))}

                    {/* Add Rule Button */}
                    {!readMode && !menuView && (
                      <CustomTooltip
                        title={
                          formik?.values?.rules?.length >= Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER)
                            ? "You have reached the maximum number of rules"
                            : ""
                        }
                        placement="top"
                      >
                        <Button
                          type="button"
                          variant="ghost"
                          size="large"
                          style={{ marginTop: "8px" }}
                          disabled={Boolean(
                            formik?.values?.rules?.length >= Number(window.__RUNTIME_CONFIG__.KONAN_MAX_RULE_NUMBER),
                          )}
                          onClick={() => {
                            addRule({ ...EMPTY_FILTER_CONDITION })

                            handleRulesUpdate([...formik?.values?.rules, { ...EMPTY_FILTER_CONDITION }])
                          }}
                        >
                          + Add Rule
                        </Button>
                      </CustomTooltip>
                    )}
                  </Fragment>
                )}
              </FieldArray>
            </FormikProvider>
          </Grid>
        </Grid>

        {!menuView && !readMode && (
          <div style={{ position: "absolute", bottom: "-40px", left: "48%" }}>
            <Button
              onClick={() => {
                if (props?.data?.updateGraph) {
                  props?.data?.updateGraph({
                    areBranchesSwapped: { filterNodeId: props?.id as string },
                  })
                }
              }}
              variant="secondary"
              size="small"
            >
              <SwapHorizOutlinedIcon fontSize="small" />
            </Button>
          </div>
        )}

        {!menuView &&
          sourceEdges?.length > 0 &&
          sourceEdges?.map((_, index) => (
            <Handle
              style={{
                left: `${getNodeHandleXPosition(index, nodeWidth as number, sourceEdges?.length)}px`,
                opacity: 0,
              }}
              type="source"
              position={Position.Bottom}
              id={index === 0 ? "a" : "b"}
              isConnectable={true}
              key={`${index}`}
            />
          ))}
      </Card>
    </div>
  )
}
