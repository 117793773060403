import React, { useEffect, useMemo, useState } from "react"

import { Edge, Handle, Node, Position, useReactFlow, useStoreApi } from "reactflow"

import CachedIcon from "@mui/icons-material/Cached"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { Card, Grid, IconButton, Link } from "@mui/material"
import {
  Chip,
  InputChangeEvent,
  InputText,
  NotificationUtils,
  RadioButton,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"
import { v4 as uuidv4 } from "uuid"

import { ValueBlock } from "../../../components/ValueBlock"
import { BaseSimpleDialog } from "../../../components/dialogs/BaseSimpleDialog"
import { getTheme } from "../../../hooks/UseTheme"
import { SchemaFeature, UpdateGraphParams } from "../../../types/custom/workflows"
import { getDescendantNodes } from "../../../utils/workflowHelpers"
import { OptionsWithExplanationDialog } from "../../Scorecards"
import { WorkflowExample } from "./WorkflowExample"

import styles from "./CustomCards.module.scss"

type SchemaFeatures = {
  features: Array<SchemaFeature>
}
// props for loop start node
interface LoopStartNodeType {
  id?: string
  menuView?: boolean
  data?: {
    updateGraph: (data: UpdateGraphParams) => void
    readMode: boolean
    strategy: "Parallel" | "Combined" | "PARALLEL" | "COMBINED"
    loopName: string
    loopId: string
    schema: Array<SchemaFeature> | SchemaFeatures
    input_lists: Array<string>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>
  }
}

/**
 * LoopStartNode component renders a card representing the start of a loop in a workflow.
 * It can be displayed in different views depending on the `menuView` prop.
 * The component includes functionality for updating loop names, changing strategies, and removing nodes.
 *
 * @param {string} id - The optional ID of the node.
 * @param {boolean} menuView - A boolean indicating if the node is being viewed in a menu.
 * @param {record} data - Optional data object containing the updateGraph function, readMode, strategy, loopName, loopId, schema, and formik.
 *
 * @returns {React.ReactElement} The rendered LoopStartNode component.
 */
export function LoopStartNode(props: Readonly<LoopStartNodeType>): React.ReactElement {
  const { menuView, data, id } = props

  const readMode = data?.readMode
  const nodeId = id

  const shouldDisplayStartegyOptions =
    !readMode && !menuView && props?.data?.input_lists && props?.data?.input_lists?.length > 1

  // instance of our state management that share our nodes and edges across all component
  const store = useStoreApi()

  // this is reactflow helper function to delete passed nodes as well as all the connected edges from the passed nodes
  const { getEdges } = useReactFlow()
  const { setNodes, getNodes, edges } = store.getState()

  const theme = getTheme()

  const [isExplanationDialogOpen, setIsExplanationDialogOpen] = useState<boolean>(false)

  const [isConfirmRemoveNodeDialogOpen, setIsConfirmRemoveNodeDialogOpen] = useState<boolean>(false)

  const [selectedValues, setSelectedValues] = useState<(string | number)[]>([...(props?.data?.input_lists ?? [])])

  const [value, setValue] = useState<string | number>("")

  const currentNode = getNodes()?.find((node) => node.id === props?.id)

  const loopEndNode = getNodes().find(
    (node) => node.data?.parentLoopId === currentNode?.data?.loopId && node?.data?.nodeType === "LoopEndNode",
  )

  const currentSchema = useMemo(() => {
    return !readMode ? props?.data?.formik?.values?.features : (props?.data?.schema as SchemaFeatures)?.features
  }, [props?.data?.formik?.values?.features, props?.data?.schema, readMode])

  const computedFeatureNames = useMemo(() => {
    return props?.data?.formik?.values?.computedFeatures
      ?.filter((item: { uuid: string; value: string }) => item?.value?.length > 0)
      ?.map((feat: { uuid: string; value: string }) => feat?.value)
  }, [props?.data?.formik?.values?.computedFeatures])

  // state contains all features (computed, schema)
  const [allFeatures, setAllFeatures] = useState([
    ...(currentSchema ?? []),
    ...(computedFeatureNames?.length > 0
      ? computedFeatureNames?.map((name: string) => {
          return {
            name,
            type: "NUMBER",
            is_required: false,
            new: false,
            id: uuidv4(),
            source: "workflow",
          }
        })
      : []),
  ])

  /**
   * Handles the removal of a loop start node along with its associated nodes and edges.
   * This function removes the loop start node, loop end node, and all nodes and edges between them.
   * It also updates the graph by reassigning edges to maintain connectivity.
   */
  const handleDeleteLoopStartNode = (): void => {
    // Get all descendant nodes of the loop start node
    const descendantNodes = getDescendantNodes(getNodes(), props?.id as string, "loop-start")

    // getting the nodeId that loop end node was poiting to
    const targetNodeOfLoopEndNode = getEdges()?.find((edge) => edge?.source === loopEndNode?.id)?.target

    // Add the loop start node to the list of nodes to delete
    descendantNodes.push(currentNode as Node)

    const remainingNodes = getNodes().filter(
      (node) => !descendantNodes.some((deletedNode) => deletedNode.id === node.id),
    )

    const targetNodeOfLoopEndNodeIndex = remainingNodes?.findIndex((node) => node?.id === targetNodeOfLoopEndNode)

    if (targetNodeOfLoopEndNodeIndex !== -1) {
      remainingNodes[targetNodeOfLoopEndNodeIndex].data = {
        ...remainingNodes[targetNodeOfLoopEndNodeIndex]?.data,
        parentId: currentNode?.data?.parentId,
      }
    }

    // Create a Set of descendant node IDs for faster lookup
    const descendantNodeIds = new Set(descendantNodes.map((node) => node?.id))

    // Find all edges connected to the nodes to be deleted
    const edgesToDelete = edges.filter(
      (edge) => descendantNodeIds.has(edge.source) || descendantNodeIds.has(edge.target),
    )

    // Find the remaining edges
    const edgesToDeleteIds = new Set(edgesToDelete.map((edge) => edge.id))

    const remainingEdges = edges.filter((edge) => !edgesToDeleteIds.has(edge.id))

    const originalEdge = edgesToDelete?.find((edge) => edge.target === nodeId)

    const originalSourceLoopEndNodeEdge = edgesToDelete?.find((edge) => edge.source === loopEndNode?.id)

    const modifiedEdge: Edge = {
      ...originalEdge,
      id: originalEdge?.id as string,
      sourceHandle: originalEdge?.sourceHandle,
      source: originalEdge?.source as string,
      target: originalSourceLoopEndNodeEdge?.target as string,
    }

    if (props?.data?.updateGraph) {
      props?.data?.updateGraph({
        dataAfterDeletion: {
          nodes: remainingNodes,
          edges:
            modifiedEdge?.data?.text === "true" ? [modifiedEdge, ...remainingEdges] : [...remainingEdges, modifiedEdge],
        },
      })
    }

    setIsConfirmRemoveNodeDialogOpen(false)

    NotificationUtils.toast(`Loop nodes removed!`, {
      snackBarVariant: "positive",
    })
  }

  const handleLoopNameChange = (e: InputChangeEvent<Element>): void => {
    setNodes(
      getNodes().map((node) => {
        const newNode = { ...node }
        if (node.id === nodeId) {
          newNode.data = {
            ...newNode.data,
            loopName: e.target.value,
          }
        }

        // setting the loop name in the loop end node
        if (node.data?.parentLoopId === props?.data?.loopId) {
          newNode.data = {
            ...newNode.data,
            parentLoopName: e.target.value,
          }
        }
        return newNode
      }),
    )
  }

  const handleAddNewOption = (): void => {
    const newFeat = {
      name: value,
      type: "LIST",
      is_required: true,
      id: uuidv4(),
      source: "workflow",
      new: true,
    }

    if (!!value) {
      props?.data?.formik?.setFieldValue("features", [...(props?.data?.formik?.values?.features ?? []), newFeat])

      setSelectedValues((prev: (string | number)[]) => [...prev, value])

      setNodes(
        getNodes().map((node) => {
          const newNode = { ...node }
          if (node.id === nodeId) {
            newNode.data = {
              ...newNode.data,
              input_lists: [...((props?.data?.input_lists as string[]) ?? []), value],
            }
          }
          return newNode
        }),
      )
    }
  }

  // Update allFeatures whenever currentSchema or computedFeatureNames change
  useEffect(() => {
    const computedFeatures =
      computedFeatureNames?.map((name: string) => ({
        name,
        type: "NUMBER",
        is_required: false,
        new: false,
        id: uuidv4(),
        source: "workflow",
      })) || []

    const updatedAllFeatures = [
      ...(currentSchema?.filter((feat: SchemaFeature) => !!feat?.name) ?? []),
      ...(computedFeatures ?? []),
    ]
    setAllFeatures(updatedAllFeatures)
  }, [currentSchema, computedFeatureNames])

  return (
    <div>
      {isConfirmRemoveNodeDialogOpen && (
        <BaseSimpleDialog
          isLoading={false}
          onAccept={handleDeleteLoopStartNode}
          open
          onClose={() => setIsConfirmRemoveNodeDialogOpen(false)}
          mode="remove-loop-node"
          name=""
        />
      )}

      {isExplanationDialogOpen && (
        <OptionsWithExplanationDialog
          setSelectedOption={(strategy) =>
            setNodes(
              getNodes().map((node) => {
                const newNode = { ...node }
                if (node.id === nodeId) {
                  newNode.data = {
                    ...newNode.data,
                    strategy,
                  }
                }
                return newNode
              }),
            )
          }
          selectedOption={props?.data?.strategy}
          dialogTitle="Loop Block Explanation"
          options={[
            {
              value: "Parallel",
              label: "Parallel Output",
              description:
                "For each iteration use the same indexed item from every LOOP INPUT feature in the order they are listed.",
              component: <WorkflowExample strategy="Parallel" />,
            },
            {
              value: "Combined",
              label: "Combined Output",
              description:
                "For each iteration use an item with all other LOOP INPUT feature items in the order they are listed.",
              component: <WorkflowExample strategy="Combined" />,
            },
          ]}
          open
          onClose={() => setIsExplanationDialogOpen(false)}
          shouldEnableSelectingMethods={!readMode}
        />
      )}

      <Card className={`${menuView ? styles.card : styles.loopStartCard} disableKeyboardA11y nodrag`}>
        {!menuView && (
          <Handle style={{ opacity: 0 }} type="target" position={Position.Top} id="b" isConnectable={true} />
        )}

        <Grid
          container
          flexWrap="nowrap"
          gap={"6px"}
          display="flex"
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Grid xs={12} container item display="flex" flexWrap="nowrap">
            {/* Node icon */}
            <Grid item xs={menuView ? 1.9 : 1.6}>
              <Grid item className={styles.loopStartCardType}>
                <CachedIcon sx={{ color: theme.palette.grayscale.text[1] }} />
              </Grid>
            </Grid>

            <Grid
              width={"100%"}
              item
              xs
              display="flex"
              flexDirection={"column"}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid display="flex" item xs={12} container justifyContent="space-between">
                <Grid item>
                  <Typography variant="label" variantColor={2}>
                    LOOP START
                  </Typography>
                </Grid>

                <Grid item display="flex" alignItems="flex-start" justifyContent="space-between" justifySelf="flex-end">
                  {shouldDisplayStartegyOptions && (
                    <Grid
                      onClick={() => setIsExplanationDialogOpen(true)}
                      item
                      alignItems="flex-start"
                      justifySelf="flex-end"
                      mt={"-4px"}
                    >
                      <Link className="explainAnchor" underline="none">
                        {readMode && props?.data?.strategy
                          ? `${props?.data?.strategy?.charAt(0)?.toUpperCase() + props?.data?.strategy?.slice(1)?.toLowerCase()}`
                          : "Explain"}
                      </Link>
                    </Grid>
                  )}

                  {/* close Icon */}
                  {!menuView && !readMode && (
                    <Grid item justifySelf="flex-end">
                      <IconButton
                        style={{ marginTop: "-10px", marginRight: "-5px" }}
                        size="small"
                        onClick={() => setIsConfirmRemoveNodeDialogOpen(true)}
                      >
                        <CloseIcon fontSize="small" className={styles.remove} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                flexWrap={"nowrap"}
                gap={0.5}
                item
                xs={12}
                container
                width="100%"
                height={!readMode ? "40px" : "30px"}
              >
                <Grid item xs={readMode || !shouldDisplayStartegyOptions ? 12 : 9} marginTop={0.5}>
                  {!readMode ? (
                    <InputText
                      id="loop_name"
                      placeholder="Loop name"
                      value={props?.data?.loopName}
                      handleChange={(e) => handleLoopNameChange(e)}
                      disabled={Boolean(menuView || readMode)}
                      fullWidth
                      hideDescription
                      max={50}
                    />
                  ) : (
                    <ValueBlock size={12} value={props?.data?.loopName ?? ""} />
                  )}
                </Grid>

                {shouldDisplayStartegyOptions && (
                  <Grid item xs marginTop={readMode && !menuView ? "-4px" : "1px"}>
                    <RadioButton
                      value={`Parallel`}
                      onChange={(e) => {
                        setNodes(
                          getNodes().map((node) => {
                            const newNode = { ...node }
                            if (node.id === nodeId) {
                              newNode.data = {
                                ...newNode.data,
                                strategy: e.target.value,
                              }
                            }
                            return newNode
                          }),
                        )
                      }}
                      checked={props?.data?.strategy === "Parallel" || props?.data?.strategy === "PARALLEL"}
                      label={"Parallel"}
                      disabled={Boolean(menuView || readMode)}
                      style={{ marginBottom: "0.2rem" }}
                      name={`Parallel-${uuidv4()}`}
                    />

                    <RadioButton
                      value={`Combined`}
                      onChange={(e) => {
                        setNodes(
                          getNodes().map((node) => {
                            const newNode = { ...node }
                            if (node.id === nodeId) {
                              newNode.data = {
                                ...newNode.data,
                                strategy: e.target.value,
                              }
                            }
                            return newNode
                          }),
                        )
                      }}
                      checked={props?.data?.strategy === "Combined" || props?.data?.strategy === "COMBINED"}
                      disabled={Boolean(menuView || readMode)}
                      label={"Combined"}
                      name={`Combined-${uuidv4()}`}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {(!readMode || (readMode && props?.data?.input_lists && props?.data?.input_lists?.length > 0)) && (
            <Grid item xs={12} flexWrap={"nowrap"} display="flex">
              {readMode && props?.data?.input_lists && props?.data?.input_lists?.length > 0 ? (
                <Grid container className={styles.chipsContainer}>
                  {props?.data?.input_lists?.map((list, index) => (
                    <Chip key={index} size="small">
                      {list}
                    </Chip>
                  ))}
                </Grid>
              ) : (
                !readMode && (
                  <InputText
                    startAdornment={<SearchIcon fontSize="small" sx={{ color: "var(--grayscale-text-2)" }} />}
                    value={value}
                    width={menuView ? 300 : 350}
                    multiple
                    shouldAcceptNewValues
                    disabled={menuView}
                    scrollDirection="horizontal"
                    options={allFeatures
                      ?.filter((feat) => feat?.type === "LIST" || feat?.type === "UNDEFINED")
                      ?.map((feature) => feature?.name)}
                    setSelectedValues={(selectedOptions) => {
                      setNodes(
                        getNodes().map((node) => {
                          const newNode = { ...node }
                          if (node.id === nodeId) {
                            newNode.data = {
                              ...newNode.data,
                              input_lists: [...selectedOptions],
                            }
                          }
                          return newNode
                        }),
                      )
                      setSelectedValues(selectedOptions as string[])
                    }}
                    handleChange={(e: InputChangeEvent) => setValue(e.target.value)}
                    selectedValues={selectedValues}
                    hideDescription
                    placeholder="Add input lists"
                    menuProps={{
                      menuMaxContent: true,
                    }}
                    noEntriesProps={{
                      handleAddNewValue: handleAddNewOption,
                      shouldAcceptNewValues: !!value,
                      message: "No features found!",
                    }}
                  />
                )
              )}
            </Grid>
          )}
        </Grid>

        {!menuView && (
          <Handle style={{ opacity: 0 }} type="source" position={Position.Bottom} id="a" isConnectable={true} />
        )}
      </Card>
    </div>
  )
}
