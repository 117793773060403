import React, { Fragment, useEffect, useMemo, useState } from "react"

import { useMutation } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"

import * as Yup from "yup"
import { Box, CircularProgress, Grid, Tab, Tabs, useMediaQuery } from "@mui/material"
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import { Button, InputPassword, InputText, Select, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { useFormik } from "formik"
import { jwtDecode } from "jwt-decode"

import { SidAPI } from "../../services/SidAPI"
import { CustomRegistrationInput } from "../../types/custom/authentication"
import { CustomRegistration } from "../../types/generated/authentication/CustomRegistration"
import { TokenObtainPairResponse } from "../../types/generated/authentication/TokenObtainPairResponse"
import { Auth } from "../../utils/auth"
import { EntryDisclaimer } from "./EntryDisclaimer"
import { Verification } from "./VerificationScreen"

import styles from "./Entry.module.scss"

// Yup validation schema object
const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Must be a valid email").trim().required("Required"),
  password: Yup.string().required("Required").min(8, "Password must be 8 characters minimum"),
  rePassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  position: Yup.number().required("Required"),
})

type LocationProps = {
  state: {
    // Response coming back from google api after successful login
    credentialResponse: CredentialResponse
  }
}

/**
 * SignUp form component
 * @return  {<SignUp />}
 */
export function SignUp(): React.ReactElement {
  const navigate = useNavigate()
  const location = useLocation() as LocationProps

  const breakpointMatch = useMediaQuery("(max-width:400px)")

  const [entryTab, setEntryTab] = useState<"login" | "signup">("signup")

  const [isSignedUp, setIsSignedUp] = useState<boolean>(false)
  // renders some fields conditionally based on the credentialResponse
  const [isGoogleSignup, setIsGoogleSignup] = useState<boolean>(false)

  const [isGoogleAccVerified, setIsGoogleAccVerified] = useState<boolean>(true)
  const [isGoogleLoginFailure, setIsGoogleLoginFailure] = useState<boolean>(false)

  // Memoizing google's credentialResponse on change
  const credentialResponse = useMemo(
    () => location.state?.credentialResponse || null,
    [location.state?.credentialResponse],
  )

  // Request mutations
  const signupMutation = useMutation<AxiosResponse<CustomRegistration>, AxiosError, CustomRegistrationInput>(
    SidAPI.signup,
  )

  const googleloginMutation = useMutation<AxiosResponse<TokenObtainPairResponse>, AxiosError, string>(
    SidAPI.googleLogin,
  )

  const onGoogleLoginSuccess = async (credentialResponse: CredentialResponse): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let response: any

    // Checks if google account is verified before Login/SignUp
    // if account isn't verified login isn't attempted
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((jwtDecode(credentialResponse.credential as string) as any).email_verified === false) {
      setIsGoogleAccVerified(false)
      return
    }

    try {
      // Tries to login, if it fails it signs the user up
      response = await SidAPI.googleSignup(credentialResponse.credential as string)
    } catch (error) {
      try {
        response = await googleloginMutation.mutateAsync(credentialResponse.credential as string)
      } catch (error) {
        setIsGoogleLoginFailure(true)
        return
      }
    }

    // Logs the user in after login/signup
    const data = response.data
    await Auth.login(data.access, data.refresh)
    navigate("/", { replace: true })
  }

  // SignUp form control
  const formik = useFormik({
    // TODO: Formik names should be the same as the API response so that Errors can be correctly mapped
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      position: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Logout the user before registering, this is to flush the access token from the localStorage
        Auth.logOut()
        await signupMutation.mutateAsync(values)
        setIsSignedUp(true)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.response?.data) {
          formik.setErrors(e.response.data)
        }
      }
    },
  })

  // setting google signup to true if google signup is successful
  useEffect(() => {
    credentialResponse !== null && setIsGoogleSignup(true)
  }, [credentialResponse])

  return (
    <Grid>
      {isSignedUp || googleloginMutation.error?.response?.status === 403 ? (
        <Verification email={formik.values.email} isPostSignup={true} createAccount={() => setIsSignedUp(false)} />
      ) : (
        <Fragment>
          <Grid container justifyContent="center" className={styles.containerWidth}>
            {/* Network error; shown if client cant reach api for any reason */}
            {signupMutation.isError && signupMutation.error?.message === "Network Error" && (
              <Fragment>
                <Snackbar variant="negative" fullWidth description="Network Error. Check your internet connection." />
                <Box mt={2} />
              </Fragment>
            )}

            {/**
             * Google SignUp errors
             * 1. google account isn't verified
             * 2. Google account signup failure
             */}
            {!isGoogleAccVerified && (
              <Grid item xs={12}>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description="Google account isn't verified, please verify your account and try again"
                />
                <Box mt={2} />
              </Grid>
            )}

            {isGoogleLoginFailure && (
              <Grid item xs={12}>
                <Snackbar
                  variant="negative"
                  fullWidth
                  description="Signup with google failed please try again later or fill up the signup form"
                />
                <Box mt={2} />
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="center" className={styles.signupContainer}>
            <Grid item xs={12}>
              <Tabs
                value={entryTab}
                onChange={(_e, value: "login" | "signup") => {
                  setEntryTab(value)
                }}
                indicatorColor="primary"
                textColor="primary"
                className={"entry-tabs"}
              >
                <Tab
                  label="login"
                  value="login"
                  className={"entry-tab"}
                  onClick={() => {
                    navigate("/login")
                  }}
                />
                <Tab label="Create account" value="signup" className={"entry-tab"} />
              </Tabs>
            </Grid>

            <Grid container item xs={12} className={styles.signupHeader}>
              <Grid item>
                <Typography variant="h2-bold" color="neutral">
                  Start Your Free 14-Day Trial
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="p" color="neutral">
                  No credit card required.
                </Typography>
              </Grid>
            </Grid>

            {/* breakpoints should be the same as those in Login.tsx */}
            <form onSubmit={formik.handleSubmit} className={styles.signupForm}>
              <Grid container justifyContent="space-between">
                <Grid item paddingRight={1} xs={6}>
                  <InputText
                    fullWidth
                    id="firstName"
                    label="First Name"
                    placeholder="John"
                    error={Boolean(formik.errors.firstName) && formik.touched.firstName && formik.errors.firstName}
                    description={"Required"}
                    value={formik.values.firstName}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    required
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputText
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    placeholder="Doe"
                    error={Boolean(formik.errors.lastName) && formik.touched.lastName && formik.errors.lastName}
                    description={"Required"}
                    value={formik.values.lastName}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    required
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} mt={2}>
                <Select
                  id="position"
                  label="Position"
                  type="text"
                  handleBlur={formik.handleBlur}
                  value={formik.values.position}
                  handleChange={(e) => formik.setFieldValue("position", e.target.value)}
                  error={formik.touched.position && Boolean(formik.errors.position)}
                  fullWidth
                  optionsWithValues={[
                    "Data Scientist",
                    "Project Manager",
                    "Student",
                    "DevOps Engineer",
                    "Freelancer",
                    "Developer",
                    "Other",
                  ].map((item, index) => {
                    return { label: item, value: index }
                  })}
                />
              </Grid>

              <Grid container spacing={1} mt={1}>
                <Grid item xs={12}>
                  <InputText
                    fullWidth
                    id="email"
                    label="Email"
                    placeholder="John@Doe.com"
                    error={
                      Boolean(formik.errors.email) && formik.errors.email && typeof formik.errors.email !== "string"
                        ? formik.errors.email[0]
                        : formik.errors.email
                    }
                    description={"We'll send you a confirmation email to verify it's you"}
                    value={formik.values.email}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    required
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </Grid>

              <Box mt={2} />
              {/* TODO:: configure google signup to view a different signup page,
               if there are missing data in the google account used to register */}
              {!isGoogleSignup && (
                <Fragment>
                  <Grid item xs={12}>
                    <InputPassword
                      fullWidth
                      id="password"
                      error={
                        Boolean(formik.errors.password) &&
                        formik.errors.password &&
                        typeof formik.errors.password !== "string"
                          ? formik.errors.password[0]
                          : formik.errors.password
                      }
                      value={formik.values.password}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: "16px" }}>
                    <InputPassword
                      fullWidth
                      id="rePassword"
                      error={Boolean(formik.errors.rePassword) && formik.touched.rePassword && formik.errors.rePassword}
                      value={formik.values.rePassword}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      confirmPassword
                    />
                  </Grid>
                  <Box mt={3} />
                </Fragment>
              )}

              <Grid item xs={12} paddingBottom={2}>
                <Button
                  variant="primary"
                  type="submit"
                  fullWidth
                  disabled={formik.isSubmitting || !(formik.dirty && formik.isValid)}
                >
                  {formik.isSubmitting ? <CircularProgress color="inherit" size={20} /> : "Create account"}
                </Button>

                {!isGoogleSignup && (
                  <Fragment>
                    <hr className={styles.divider} />
                    <GoogleLogin
                      width={breakpointMatch ? 252 : 312}
                      theme="outline"
                      size="medium"
                      text="signup_with"
                      context="signup"
                      onSuccess={onGoogleLoginSuccess}
                      useOneTap={false}
                      auto_select={false}
                      locale="en"
                    />
                  </Fragment>
                )}
              </Grid>
            </form>
          </Grid>

          <Box mt={1} />
          <EntryDisclaimer type="signup" />
        </Fragment>
      )}
    </Grid>
  )
}
